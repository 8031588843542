<template>
  <div>
    <v-container fluid>
      <v-card class="transparent" flat>
        <v-card-text class="text-center mt-15">
          <v-avatar size="150" tile>
            <svg fill="#FF2442" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
              <g stroke-width="0"></g>
              <g stroke-linecap="round" stroke-linejoin="round"></g>
              <g>
                <path
                    d="M58,56.5H54.54a2,2,0,0,1,0-4L32,13.5,9.48,52.5H43.79a2,2,0,0,1,0,4H6a2,2,0,0,1-1.73-3l26-45a2,2,0,0,1,3.46,0l26,45a2,2,0,0,1-1.73,3Z"></path>
                <path d="M32,48.54a2,2,0,0,1-2-2V45.21a2,2,0,0,1,4,0v1.33A2,2,0,0,1,32,48.54Z"></path>
                <path d="M32,41.54a2,2,0,0,1-2-2V27.21a2,2,0,0,1,4,0V39.54A2,2,0,0,1,32,41.54Z"></path>
              </g>
            </svg>
          </v-avatar>

          <h2 class="my-8 text-uppercase red--text">
            Supprimer mon compte TIP !
          </h2>


          <v-row justify="center">
            <v-col cols="11" lg="5">
              <v-alert v-if="message" border="left" class="text-left fs-14" dense type="success">
                {{ message }}
              </v-alert>
              <p>
                Veuillez confirmer la suppression de votre compte. Cette action est irréversible.
              </p>
            </v-col>
          </v-row>


          <v-row justify="center">
            <v-col cols="11" lg="5">

              <v-text-field v-model="phone"
                            :error-messages="errors.phone"
                            hide-details="auto"
                            label="Entrez votre numéro de téléphone"
                            outlined
                            prepend-inner-icon="mdi-phone"
                            type="number"></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="11" lg="5">
              <vue-recaptcha :sitekey="siteKey" @verify="onCaptchaVerified"></vue-recaptcha>
              <p class="red--text text-left" v-if="errors && errors.recaptcha">
                {{ errors.recaptcha.toString() }}
              </p>

            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="11" lg="5">
              <v-btn :loading="isLoading" block color="red" dark depressed @click="sendRequest">
                Confirmer la suppression
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

      <v-row class="mt-16 pt-16">
        <v-col class="text-center" cols="12">
          COPYRIGHT © {{ (new Date().getFullYear()) }} <a class="text-decoration-none" href="#" target="_blank">TIP</a>,
          Tous droits réservés
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';
import axios from "axios";

export default {
  components: {VueRecaptcha},
  data() {
    return {
      isLoading: false,
      phone: '',
      message: '',
      errors: {},
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      recaptchaToken: ''
    }
  },
  methods: {
    onCaptchaVerified(token) {
      this.recaptchaToken = token
    },
    sendRequest() {
      this.errors = {};
      this.isLoading = true;
      axios.post(process.env.VUE_APP_BASE_URL + 'deletion-requests/store', {
        phone: this.phone,
        recaptcha: this.recaptchaToken,
      }).then(() => {
        this.isLoading = false;
        this.message = 'Votre demande de suppression a été envoyée avec succès.';
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.response.data.errors;
        console.log(err);
      });
    },
  }
}
</script>

<style scoped>

</style>